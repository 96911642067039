export default function replaceHighlightHtml(text: string) {
  const style = `
    color: #27797d;
    word-break: break-word;
  `;

  const regex = /\*\*(.*?)\*\*/g;
  const replacedText = text.replace(regex, (_, highlight) => {
    return `<span style="${style}">${highlight}</span>`;
  });

  return replacedText;
}
